import Hero from "./Hero";
import Synopsis from "./Synopsis"
import SelectedWorks from "./SelectedWorks";

function Home() {
    return (
        <div className="Home">
            <Hero />
            <Synopsis />
            <SelectedWorks />
        </div>
    );
}

export default Home;

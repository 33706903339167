import styled from 'styled-components'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"
import { Link } from "react-router-dom";

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    p {
        max-width: calc(100% - 48px);
        width: 480px;
    }
    margin: 64px 0;
    a {
        color: black;
    }
`

function Home() {
    const { language } = useContext(StateContext)

    return (
        <div className="Synopsis">
            <TextContainer>
                <p> {translations[language].synopsis[0]}
                    <br /><br />
                    {translations[language].synopsis[1]} <Link className='underlineAnimateReverse' to='/services'>{translations[language].synopsis[2]}</Link> {translations[language].synopsis[3]}
                </p>
            </TextContainer>
        </div>
    );
}

export default Home;
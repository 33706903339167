import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"
import styled from 'styled-components'

const PageContainer = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;   
    color: white; 
    .divider {
        height: 2px;
        width: 100%;
        background-color: white;
    }
`
const ProfileCard = styled.div`
    width: 480px;
    max-width: calc(100% - 48px);
    white-space: pre-line;
`

function OurStory() {
    const { language } = useContext(StateContext)
    return (
        <div className="Hero">
            <PageContainer>

                <ProfileCard>
                    <h2>{translations[language].ourStoryTitle}</h2>
                    <div className='divider'></div>
                    <p> {translations[language].ourStoryContent} </p>
                </ProfileCard>

            </PageContainer>
        </div>
    );
}

export default OurStory;

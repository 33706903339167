import styled from 'styled-components'
import { Link } from "react-router-dom";
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const PageContainer = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;   
    color: white; 
    .divider {
        height: 2px;
        width: 100%;
        background-color: white;
    }
    h2 {
        font-size: 48px;
        margin: 0;
    }
    a {
        color: white; 
    }
`

function NotFoundPage() {
    const { language } = useContext(StateContext)
    return (
        <div className="NotFoundPage">
            <PageContainer>
                <h2>404</h2>
                <p>Page not found</p>
                <Link className='underlineAnimateReverse whiteUnderline' to='/'>{translations[language].links.home}</Link>
            </PageContainer>
        </div>
    );
}

export default NotFoundPage;
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { translations } from "../Translations";
import { useContext } from "react";
import { StateContext } from "../App.js";
import vectorLogo from "../media/vector-logo.svg";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  ${(props) => props.darkNavBar && "background-color: black;"}
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
`;
const Logo = styled.img`
  margin-left: 20px;
  width: 69px;
`;
const DesktopLinks = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  li {
    margin: 0 8px;
  }
  a {
    color: white;
  }
  @media only screen and (max-width: 959px) {
    display: none;
  }
  text-shadow: 0px 8px 8px rgb(0 0 0 / 50%);
`;

const MenuButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
  width: 32px;
  div {
    background-color: white;
    height: 3px;
    transition: 0.2s;
  }
  .topLine {
    width: 32px;
  }
  .midLine {
    width: 26px;
  }
  .bottomLine {
    width: 20px;
  }
  :hover {
    .topLine {
      width: 20px;
    }
    .midLine {
      width: 32px;
    }
    .bottomLine {
      width: 26px;
    }
  }
  @media only screen and (min-width: 960px) {
    display: none;
  }
`;
const MenuOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 90%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: white;
    font-size: 48px;
    margin-bottom: 32px;
    /* ${(props) =>
      !props.animateFade &&
      "opacity: 0; transform: translateY(100px) scale(0)"}; */
  }
  transition: opacity 0.2s;
  display: ${(props) => !props.showMenu && "none"};
  opacity: ${(props) => (props.animateFade ? "1" : "0")};
  /* a:nth-child(1) {transition: 0.4s};
    a:nth-child(2) {transition: 0.6s};
    a:nth-child(3) {transition: 0.8s};
    a:nth-child(4) {transition: 1s}; */
  @media only screen and (min-width: 960px) {
    display: none;
  }
`;

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [animateFade, setAnimateFade] = useState(false);
  const [darkNavBar, setDarkNavBar] = useState(false);
  const { language } = useContext(StateContext);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    if (
      document.body.scrollTop > 256 ||
      document.documentElement.scrollTop > 256
    ) {
      setDarkNavBar(true);
    } else {
      setDarkNavBar(false);
    }
  };

  const toggleMenu = () => {
    if (!showMenu) {
      setShowMenu(true);
      setTimeout(() => {
        setAnimateFade(true);
      }, 0);
    } else {
      setAnimateFade(false);
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  };

  return (
    <>
      <Nav darkNavBar={darkNavBar}>
        <Link onClick={toggleMenu} style={{ display: "flex" }} to="/">
          <Logo src={vectorLogo} alt="Website Logo"></Logo>
        </Link>
        <DesktopLinks>
          <li>
            <Link className="underlineAnimate whiteUnderline" to="/">
              {translations[language].links.home}
            </Link>
          </li>
          <li>
            <Link className="underlineAnimate whiteUnderline" to="/about">
              {translations[language].links.about}
            </Link>
          </li>
          <li>
            <Link className="underlineAnimate whiteUnderline" to="/services">
              {translations[language].links.services}
            </Link>
          </li>
          <li>
            <Link className="underlineAnimate whiteUnderline" to="/contact">
              {translations[language].links.contact}
            </Link>
          </li>
        </DesktopLinks>
        <MenuButton onClick={toggleMenu}>
          <div className="topLine"></div>
          <div className="midLine"></div>
          <div className="bottomLine"></div>
        </MenuButton>
      </Nav>
      <MenuOverlay showMenu={showMenu} animateFade={animateFade}>
        <Link
          onClick={toggleMenu}
          className="underlineAnimate whiteUnderline"
          to="/"
        >
          {translations[language].links.home}
        </Link>
        <Link
          onClick={toggleMenu}
          className="underlineAnimate whiteUnderline"
          to="/about"
        >
          {translations[language].links.about}
        </Link>
        <Link
          onClick={toggleMenu}
          className="underlineAnimate whiteUnderline"
          to="/services"
        >
          {translations[language].links.services}
        </Link>
        <Link
          onClick={toggleMenu}
          className="underlineAnimate whiteUnderline"
          to="/contact"
        >
          {translations[language].links.contact}
        </Link>
      </MenuOverlay>
    </>
  );
}

export default NavBar;

import styled from 'styled-components'
import videoSource from '../media/background_video.mp4'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const PageContainer = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .CrossfadeImage {
        position: absolute !important;
    }
    video {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
`
const TextContainer = styled.div`
    z-index: 1;
    color: white;
    text-align: center;
    max-width: calc(100% - 48px);
    width: 480px;
    h1 {
        text-shadow: 0px 8px 8px rgb(0 0 0 / 50%);
    }
`
const PageTitle = styled.h1`
    letter-spacing: .25em;
`

function Hero() {
    const { language } = useContext(StateContext)

    return (
        <div className="Hero">
            <PageContainer>
                <TextContainer>
                    <PageTitle>{translations[language].title}</PageTitle>
                    <p>{translations[language].subtitle}</p>
                </TextContainer>
                <video autoPlay loop muted>
                    <source src={videoSource} type="video/mp4"></source>
                </video>
            </PageContainer>
        </div>
    );
}

export default Hero;

import styled from 'styled-components'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const PageContainer = styled.div`
    background-color: black;
    color: white;
    padding: 64px 0;
`
const PageTitle = styled.h2`
    font-size: 32px;
    text-align: center;
`
const VideoGridContainer = styled.div`
    max-width: 960px;
    margin: auto;
    display: grid;
    @media only screen and (min-width: 960px) {
    grid-template-columns: 50% 50%;
    }
    @media only screen and (max-width: 959px) {
       grid-template-rows: auto; 
    }
`

const VideoCard = styled.div`
    height: 100%;
    text-align: center;
    .divider {
        height: 2px;
        width: 100%;
        background-color: black;
    }
`
const VideoContainer = styled.div`
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        iframe {
            position: absolute;
            top: 13.5px;
            left: 24px;
            width: calc(100% - 48px);
            height: calc(100% - 27px);
        }
`

function SelectedWorks() {

    const { language } = useContext(StateContext)
    const videos = [{ title: "Skogskungen", link: "08N5HZHW3qM" }, { title: "Il Santo Veleno", link: "TDc5kptx2mo" }, { title: "Hetkesi Koittaa", link: "ZAYKUNWtLf0" }, { title: "Forbidden Zone: Effigy", link: "iUTR5XnjABo" }]

    return (
        <div className="SelectedWorks">
            <PageContainer>
                <PageTitle>{translations[language].selectedWorks}</PageTitle>
                <VideoGridContainer>
                    {videos.map(video => (
                        <VideoCard key={video.title}>
                            <p>{video.title}</p>
                            <VideoContainer>
                                <iframe width="100%" height="100%" src={'https://www.youtube-nocookie.com/embed/' + video.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </VideoContainer>
                        </VideoCard>
                    ))}
                </VideoGridContainer>
            </PageContainer>
        </div>
    );
}

export default SelectedWorks;

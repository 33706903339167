import styled from 'styled-components'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const PageContainer = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
`
const TextContainer = styled.div`
    z-index: 1;
    max-width: calc(100% - 48px);
    h2, p {
        text-align: center
    }
    width: 480px;
    color: white;
    img {
        width: 100%;
        margin-top 32px;
        border-radius: 8px;
    }
    .divider {
        height: 2px;
        width: 100%;
        background-color: white;
    }
    a {
        color: white;
    }
`
const MainHeader = styled.h2`
font-size: 32px;
`

function Contact() {
    const { language } = useContext(StateContext)

    return (
        <div className="Contact">
            <PageContainer>
                <TextContainer>
                    <MainHeader>{translations[language].contact.contact}</MainHeader>
                    <h2>{translations[language].contact.locationTitle}</h2>
                    <p>{translations[language].contact.location}</p>
                    <h2>{translations[language].contact.generalBusinessEnquiries}</h2>
                    <p><a className='underlineAnimateReverse whiteUnderline' href='mailto:info@depfilms.com'>info@depfilms.com</a> </p>
                    <h2>{translations[language].contact.socialMedia}</h2>
                    <p><a className='underlineAnimateReverse whiteUnderline' href='https://www.youtube.com/c/DEPFilms'>Youtube</a> | <a className='underlineAnimateReverse whiteUnderline' href='https://www.facebook.com/DEP.Films'>Facebook</a> | <a className='underlineAnimateReverse whiteUnderline' href='https://www.instagram.com/dep_films_insta/'>Instagram</a></p>
                </TextContainer>

            </PageContainer>
        </div>
    );
}

export default Contact;
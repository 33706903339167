import "./App.css";
import React, { useState, useEffect } from "react";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import OurStory from "./components/OurStory";
import Team from "./components/Team";
import NotFoundPage from "./components/NotFoundPage";
import Footer from "./components/Footer";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-247127194-1";
ReactGA.initialize(TRACKING_ID);

const GlobalStyle = createGlobalStyle`
/* Color palette: #003049, #d62828, #f77f00, #fcbf49, #eae2b7 */
/* #000000, #44633f, #adc698, #d0e3c4, #ffffff */

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: black; 
  border: 2px solid transparent;
  background-clip: content-box;
  border-radius: 32px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000bf;
  border: 2px solid transparent;
  background-clip: content-box;
}

.underlineAnimate {
  text-decoration: none;
  display: inline-block;
  position: relative;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  :hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.underlineAnimateReverse {
  text-decoration: none;
  display: inline-block;
  position: relative;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  :hover:after {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
}

.whiteUnderline:after {
  background-color: white;
}

}
`;

export const StateContext = React.createContext();

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

function App() {
  const [language, setLanguage] = useState("english");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      <StateContext.Provider value={{ language, setLanguage }}>
        <Router>
          <ScrollToTop>
            <NavBar></NavBar>
            <Routes>
              <Route
                path="/about"
                element={
                  <div>
                    <OurStory></OurStory>
                    <Team></Team>
                  </div>
                }
              ></Route>
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ScrollToTop>
        </Router>
        <Footer></Footer>
        <LanguageSwitcher></LanguageSwitcher>
      </StateContext.Provider>
    </div>
  );
}

export default App;

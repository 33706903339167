export const translations = {
  english: {
    links: {
      home: "Home",
      about: "About",
      services: "Services",
      contact: "Contact",
    },
    title: "GENUINE STORIES & VISUAL MAGIC",
    subtitle:
      "With over a decade of experience both behind of and in front of the camera, we breathe the art of cinema.",
    synopsis: [
      "DEP Films is a stockholm based production company that aims to create film and media. As a collective we have been active in media for ten years and have now created our own production company. Our goal is to help you create and produce your projects and events, we are a smaller team but highly motivated and productive. We had nothing but glowing reviews and hope to work with you soon!",
      "Check out the",
      "services",
      "page for more info about what we can offer you.",
    ],
    selectedWorks: "Selected Works",
    ourStoryTitle: "Our Story",
    ourStoryContent: `10 years ago three friends started to make movies together in their backyard. Over the years this became more than just a hobby but a goal to create and spread new stories and content to a wider audience. Releasing their content on youtube the idea of a DEP Production company started to form, with their experience in content creation growing by studying and working on film they now felt confident in sharing their knowledge to others.\n\nToday DEP Films has created a large and diverse portfolio not only for themselves but for others. Filmmaking is in the very core of DEP Films and our passion for it is here for you to take part of. In some ways we are still the three kids making movies in our backyard.`,
    bios: {
      david:
        "With a natural talent for acting David is an experienced and capable actor in DEP Films. Knowing how to convey emotion and storytelling he is a scriptwriter at heart.",
      emilio:
        "With many film productions created privately and through studying in Arcada University, Directing and producing is his bread and butter.",
      pauli:
        "A natural born storyteller. Pauli has been experimenting with the arts since an early age. After film school he went directly to help others create their stories become a reality. He is the instinctive choice for cinematography and editing required.",
      inca: "With a skill in entrepreneurship Inca has created many successful businesses, being one of the first to see the potential in DEP Films.",
    },
    roles: {
      david: "Actor & writer",
      emilio: "Director & Producer",
      pauli: "Cinematographer & Editor",
      inca: "Executive Producer",
    },
    services: [
      {
        title: "Film Production",
        content:
          "We at DEP Films can create your story from scratch and help you go from a script to a fully produced short film or commercial. With our team of experienced film-makers we will help you realize any media related concept.",
      },
      {
        title: "Post Production",
        content:
          "Material already filmed? No problem, we can help you edit and put together your media for you. Anything from a simple grade or a more advanced edit with effects and music.",
      },
      {
        title: "Production Assistance",
        content:
          "Need an extra hand on set? With our motivated team we will help your production with anything from camerawork to script supervision.",
      },
      {
        title: "Events and Media",
        content:
          "We'll gladly help with any special event you need filmed or posted on social media. From shooting at events to completing a video in the post-production stage.",
      },
    ],
    contact: {
      contact: "Contact",
      locationTitle: "Location",
      location: "Stockholm, Sweden",
      generalBusinessEnquiries: "General Business inquiries",
      socialMedia: "Social Media",
    },
    footer: {
      websiteBy: "Website by",
      followUs: "Follow us:",
    },
  },
  swedish: {
    links: {
      home: "Hem",
      about: "Om oss",
      services: "Tjänster",
      contact: "Kontakt",
    },
    title: "GENUINA HISTORIER & VISUELL MAGI",
    subtitle:
      "Med över ett årtionde av erfarenhet bakom och framför kameran så andas vi filmskaparkonsten.",
    synopsis: [
      "DEP Films är ett stockholmsbaserat produktionsbolag som har som mål att skapa film och media. Som kollektiv har vi varit verksamma inom media i tio år och har nu skapat ett eget produktionsbolag. Vårt mål är att hjälpa dig att skapa och producera dina projekt och evenemang. Vi är ett mindre team men mycket motiverade och produktiva. Under åren har vi fått lysande recensioner från tidigare kollegor och kunder. Vi hoppas kunna arbeta med er snart!",
      "Kolla in",
      "tjänstesidan",
      "för mer information om vad vi kan erbjuda dig.",
    ],
    selectedWorks: "Utvalda Verk",
    ourStoryTitle: "Vår Historia",
    ourStoryContent: `För 10 år sedan började tre vänner göra film tillsammans på sin gård. Med åren blev detta mer än bara en hobby utan ett mål att skapa och sprida nya berättelser och innehåll till en bredare publik. Genom att släppa sitt innehåll på youtube började idén om ett DEP-produktionsbolag att bildas, med deras erfarenhet av innehållsskapande som växte genom att studera och arbeta med film kände de sig nu säkra på att dela med sig av sin erfarenhet till andra.\n\nIdag har DEP Films skapat en stor och mångsidig portfölj, inte bara för sig själva utan också för andra. Filmskapande är i själva kärnan av DEP Films och vår passion för det är här för dig att ta del av. På något vis är vi fortfarande de tre barnen som gör filmer på sin gård.`,
    bios: {
      david:
        "Med en naturlig talang för att skådespela är David en erfaren och kapabel skådespelare inom DEP Films. Genom att veta hur man förmedlar känslor och historieberättande är han en manusförfattare i grunden.",
      emilio:
        "Med många filmproduktioner skapade privat och genom studier vid Arcada Universitet, är regi och producentskap hans expertis.",
      pauli:
        "En naturligt född berättare. Pauli har experimenterat med konst sedan en tidig ålder. Efter filmskolan gick han direkt för att hjälpa andra att skapa verklighet av deras berättelser. Han är det självklara valet för fotografering och redigering.",
      inca: "Med en skicklighet inom entreprenörskap har Inca skapat många framgångsrika företag, och är en av de första att se potentialen i DEP Films.",
    },
    roles: {
      david: "Skådespelare & manusförfattare",
      emilio: "Regissör & producent",
      pauli: "Filmfotograf & redigerare",
      inca: "Exekutiv producent",
    },
    services: [
      {
        title: "Filmproduktion",
        content:
          "Vi på DEP Films kan skapa din berättelse från grunden och hjälpa dig att gå från ett manus till en färdigproducerad kortfilm eller reklamfilm. Med vårt team av erfarna filmskapare hjälper vi dig att förverkliga alla medierelaterade koncept.",
      },
      {
        title: "Efterbearbetning",
        content:
          "Material redan filmat? Inga problem, vi kan hjälpa dig att redigera och sätta ihop dina media åt dig. Allt från grading eller en mer avancerad redigering med effekter och musik.",
      },
      {
        title: "Produktionshjälp",
        content:
          "Behöver du en extra hand på inspelningsplatsen? Med vårt motiverade team hjälper vi din produktion med allt från kameraarbete, runner och scripta.",
      },
      {
        title: "Evenemang och media",
        content:
          "Varje speciell händelse du behöver få inspelad eller upplagd på sociala medier hjälper vi gärna till med. Från att fotografera på event till att färdigställa en video i postproduktionsstadiet.",
      },
    ],
    contact: {
      contact: "Kontakt",
      locationTitle: "Plats",
      location: "Stockholm, Sverige",
      generalBusinessEnquiries: "Allmänna företagsförfrågningar",
      socialMedia: "Sociala Medier",
    },
    footer: {
      websiteBy: "Hemsida av",
      followUs: "Följ oss:",
    },
  },
};

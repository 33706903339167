import styled from 'styled-components'
import uk from "../media/Flag_of_the_United_Kingdom.svg"
import sweden from "../media/Flag_of_Sweden.svg"
import { useContext, useEffect } from 'react';
import { StateContext } from "../App.js"

const LanguageSwitcherContainer = styled.div`
    margin: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    img {
        height: 24px;
    }
    cursor: pointer;
`

function LanguageSwitcher() {
    const flags = { english: uk, swedish: sweden };
    const { language, setLanguage } = useContext(StateContext)

    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage) setLanguage(savedLanguage);
    }, []);

    const toggleLanguage = () => {
        if (language === "swedish") {
            setLanguage("english")
            localStorage.setItem("language", "english")
        } else {
            setLanguage("swedish")
            localStorage.setItem("language", "swedish")
        }
    }

    return (
        <div className="LanguageSwitcher">
            <LanguageSwitcherContainer onClick={toggleLanguage}>
                <img src={flags[language]} alt={`Flag for ${language}`}></img>
            </LanguageSwitcherContainer>
        </div>
    );
}

export default LanguageSwitcher;
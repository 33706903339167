import styled from 'styled-components'
import postProduction from '../media/post_production.jpg'
import filmProduction from '../media/film_production.jpg'
import productionAssistance from '../media/production_assistance.png'
import eventsAndMedia from '../media/events_and_media.jpeg'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const PageContainer = styled.div`
    min-height: 100vh;
    padding: 69px 0 64px;
    display: flex;
    justify-content: center;
    background-color: black;
`
const TextContainer = styled.div`
    max-width: calc(100% - 48px);
    width: 480px;
    color: white;
    img {
        width: 100%;
        margin-top 32px;
        border-radius: 8px;
    }
    .divider {
        height: 2px;
        width: 100%;
        background-color: white;
    }
`

function Services() {
    const { language } = useContext(StateContext)

    return (
        <div className="Services">
            <PageContainer>
                <TextContainer>
                    <img src={filmProduction} alt=""></img>
                    <h2>{translations[language].services[0].title}</h2>
                    <div className='divider' />
                    <p>{translations[language].services[0].content}</p>
                    <img src={postProduction} alt=""></img>
                    <h2>{translations[language].services[1].title}</h2>
                    <div className='divider' />
                    <p>{translations[language].services[1].content}</p>
                    <img src={productionAssistance} alt=""></img>
                    <h2>{translations[language].services[2].title}</h2>
                    <div className='divider' />
                    <p>{translations[language].services[2].content}</p>
                    <img src={eventsAndMedia} alt=""></img>
                    <h2>{translations[language].services[3].title}</h2>
                    <div className='divider' />
                    <p>{translations[language].services[3].content}</p>
                </TextContainer>

            </PageContainer>
        </div>
    );
}

export default Services;
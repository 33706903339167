import styled from 'styled-components'
import vectorLogo from '../media/vector-logo.svg'
import iconYoutube from '../media/icon_youtube.svg'
import iconFacebook from '../media/icon_facebook.svg'
import iconInstagram from '../media/icon_instagram.svg'
import blobVector from '../media/blob_vector.svg'
import { translations } from '../Translations';
import { useContext } from 'react';
import { StateContext } from "../App.js"

const FooterContainer = styled.div`
    padding: 32px 0;
    min-height: 128px;
    /* background: linear-gradient(90deg, #386641ff, #6a994eff, #a7c957ff); */
    /* background: #274690; */
    /* background: #ADC698; */
    background: #090446;
    color: white;
    a{
        color: white;
    }
    overflow: hidden;
`
const WidthContainer = styled.div`
    width: 960px;
    max-width: calc(100% - 48px);
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 0;
`
const SocialMediaContainer = styled.div`
    text-align: end;
    
`

const Logo = styled.img`
    width: 64px;
`
const LinkIcon = styled.img`
    width: 32px;
    filter: invert(1);
    margin-left: 8px;
`
const Blob = styled.img`
    position: absolute;
    right: -50%;
    top: 0;
    z-index: -1;
    width: 960px;
    max-width: 100%;
    transform: translate(-50%, -50%);
`

function Footer() {
    const { language } = useContext(StateContext)

    return (
        <div className="Footer">
            <FooterContainer>
                <WidthContainer>
                    <div>
                        <Logo src={vectorLogo} alt='Website logo'></Logo>
                        {/* <p>email: <a className='underlineAnimateReverse whiteUnderline' href="mailto:info@depfilms.com" >info@depfilms.com</a></p> */}
                        <p>© {new Date().getFullYear()} DEP Films</p>
                        <p>{translations[language].footer.websiteBy} <a className='underlineAnimateReverse whiteUnderline' href='http://marcelrossi.surge.sh/'>Marcel Rossi</a></p>
                    </div>
                    <SocialMediaContainer>
                        <h2>{translations[language].footer.followUs}</h2>
                        <a href='https://www.youtube.com/c/DEPFilms'>
                            <LinkIcon src={iconYoutube} alt='Youtube link' />
                        </a>
                        <a href='https://www.facebook.com/DEP.Films'>
                            <LinkIcon src={iconFacebook} alt='Facebook link' />
                        </a>
                        <a href='https://www.instagram.com/dep_films_insta/'>
                            <LinkIcon src={iconInstagram} alt='Instagram link' />
                        </a>
                        <Blob src={blobVector} />
                    </SocialMediaContainer>

                </WidthContainer>
            </FooterContainer>
        </div>
    );
}

export default Footer;
